<template>
  <div class="badge"
       v-if="status !== null && status !== undefined"
       :class="status==='caution'? 'badge-caution': 'badge-danger'"
  >
    <slot></slot>
  </div>
  <div class="badge" v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "StatusBadge",
  components: {

  },
  props: ['status'],
};
</script>

<style scoped>
  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 3px 12px 6px;
    font-weight: var(--medium);
    font-family: var(--body-font-family);
    text-align: center;
    transition: .3s ease all;
    letter-spacing: 1px;
    width: fit-content;
    min-height: 30px;
    border-radius: 16px;
    background-color: var(--satisfying-50);
    color: var(--satisfying);
    font-size: 14px;
    border: 1px solid var(--satisfying);
    cursor: initial;
  }

  .badge-caution {
    background-color: var(--alert-50);
    color: var(--alert);
    border: 1px solid var(--alert);
  }

  .badge-danger {
    background-color: var(--error-50);
    color: var(--error);
    border: 1px solid var(--error);
  }
</style>