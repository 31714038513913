<template>
  <div class="wrapper__header">
    <header class="header" :class="{ header__index: is_in_home_page }">
      <div class="header__container">
        <svg
          class="header__icon"
          @click="isActiveBarraLateralAdmin = !isActiveBarraLateralAdmin"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 18C3.71667 18 3.47917 17.9042 3.2875 17.7125C3.09583 17.5208 3 17.2833 3 17C3 16.7167 3.09583 16.4792 3.2875 16.2875C3.47917 16.0958 3.71667 16 4 16H20C20.2833 16 20.5208 16.0958 20.7125 16.2875C20.9042 16.4792 21 16.7167 21 17C21 17.2833 20.9042 17.5208 20.7125 17.7125C20.5208 17.9042 20.2833 18 20 18H4ZM4 13C3.71667 13 3.47917 12.9042 3.2875 12.7125C3.09583 12.5208 3 12.2833 3 12C3 11.7167 3.09583 11.4792 3.2875 11.2875C3.47917 11.0958 3.71667 11 4 11H20C20.2833 11 20.5208 11.0958 20.7125 11.2875C20.9042 11.4792 21 11.7167 21 12C21 12.2833 20.9042 12.5208 20.7125 12.7125C20.5208 12.9042 20.2833 13 20 13H4ZM4 8C3.71667 8 3.47917 7.90417 3.2875 7.7125C3.09583 7.52083 3 7.28333 3 7C3 6.71667 3.09583 6.47917 3.2875 6.2875C3.47917 6.09583 3.71667 6 4 6H20C20.2833 6 20.5208 6.09583 20.7125 6.2875C20.9042 6.47917 21 6.71667 21 7C21 7.28333 20.9042 7.52083 20.7125 7.7125C20.5208 7.90417 20.2833 8 20 8H4Z"
            fill="currentColor"
          />
        </svg>
        <div data-v-41d6f4fc="" class="header__title" v-show="!isAuthenticated">
          <a
            data-v-41d6f4fc=""
            class="nav-link header_icon_admin"
            style="
              margin-left: 20px;
              margin-right: 20px;
              z-index: 2;
              cursor: pointer;
            "
          >
            <strong data-v-41d6f4fc="" @click="showModalLogin">
              Ingresar
            </strong>
          </a>
        </div>
        <div data-v-41d6f4fc="" class="header__title" v-show="isAuthenticated">
          <a data-v-41d6f4fc="" class="nav-link header_icon_admin">
            <strong data-v-41d6f4fc="" @click="refresh">
              {{ palabras }}
            </strong>
          </a>
        </div>
        <div style="display: flex; margin-left: auto; margin-right: 20px">
          <div>
            <StatusBadge v-if="false"> Este es badge </StatusBadge>
          </div>
        </div>
      </div>
    </header>
    <transition name="slide-fade">
      <div
        v-show="isActiveBarraLateralAdmin"
        class="fondo__menu__lateral__modal"
        @click="isActiveBarraLateralAdmin = !isActiveBarraLateralAdmin"
      ></div>
    </transition>
    <transition name="slide-fade">
      <RebrandBarraLateral
        v-show="currentRouteName() !== undefined"
        :isActive="isActiveBarraLateralAdmin"
        :routeName="currentRouteName()"
        @close="isActiveBarraLateralAdmin = false"
      ></RebrandBarraLateral>
    </transition>
  </div>
</template>

<script>
import RebrandBarraLateral from "@/components/MainBarraLateral";
import { mapMutations, mapState } from "vuex";
import StatusBadge from "@/components/new/buttons/StatusBadge";
export default {
  name: "RebrandHeader",
  components: { StatusBadge, RebrandBarraLateral },
  data() {
    return {
      is_in_home_page: false,
      titulo: "INICIO",
      isActiveBarraLateralAdmin: false,
    };
  },
  mounted() {
    if (this.$route.path === "/") {
      this.is_in_home_page = true;
    }
  },
  watch: {
    $route: async function () {
      this.is_in_home_page = this.$route.path === "/";
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "user"]),
    palabras() {
      const route_name = {
        home: "Inicio",
        orders: "Órdenes",
        ordersSuperAdmin: `Órdenes superadmin`,
        inhouseOrdersSuperAdmin: `Pedidos de salón`,
        historicSalesSuperAdmin: `Historial diario`,
        historic_sales: "Historial de ventas",
        soldProductsDashboard: "Productos vendidos - gráfico",
        salesDashboard: "Ventas - gráficos",
        salesRawData: "Histórico - ventas",
        sunatReport: "Reporte - Sunat",
        reporteRequerimientoDetallado:
          "Reporte - requerimiento anual de recetas - Detallado",
        reporteRequerimientoConsolidado:
          "Reporte - Programación anual de recetas - Consolidado",
        orderDetailsRaw: "Histórico - Productos vendidos",
        userDatabase: "Histórico - usuarios",
        salesPerUser: "Histórico - ventas por usuario",
        canceledOrders: "Pedidos anulados",
        menuList: "Menú",
        menuListSuperAdmin: `Menú: superadmin`,
        "perfil.cuenta": "Perfil",
        webConfig: "Configuración de la Web - Admin",
        staffAccess: "Gestión de niveles de acceso",
        inventory: "Logística - Almacén e insumos",
        proveedores: "Logística - Proveedores",
        registroProveedores: "Logística - Registro",
        programacionanualrecetas: "Programación anual de recetas",
        pucharseOrders: "Logística - Órdenes de compras",
        menuRecipe: `Recetas`,
        inhouseOrders: "Pedidos de salón",
        kitchenDisplaySystem: "Cocina",
        monthlyCostInput: "Ingresar costos",
      };
      return route_name[this.$route.name] ?? "Inicio";
    },
  },

  async created() {},
  methods: {
    ...mapMutations(["setShowModalLoginRegister"]),

    showModalLogin() {
      this.setShowModalLoginRegister(true);
    },
    currentRouteName() {
      return this.$route.name;
    },
    refresh() {
      location.reload();
    },
  },
};
</script>

<style scoped>
.header__index {
  display: none;
}

.wrapper__header {
  /*    position: sticky;
    top: 0;*/
  z-index: 10;
}

.fondo__menu__lateral__modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 3;
  background: #00000060;
  left: 0;
  top: 0;
  transition: 0.3s ease all;
  cursor: pointer;
}

.header {
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: var(--box-shadow);
  z-index: 10;
}

.header__container {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 56px;
  padding: 0 16px;
  box-shadow: var(--box-shadow);
}

.header__icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  cursor: pointer;
  color: var(--text-black);
}

.header__title a {
  font-family: var(--headings-font-family);
  color: var(--text-black);
  transition: color 0.3s linear;
  cursor: pointer;
}

.header__title a:hover {
  color: var(--primary);
}

.pending_payment_notification {
  display: flex;
  justify-content: center;
  color: white;
  background: #c22f29;
  width: 100%;
  padding: 10px 16px;
  font-size: 14px;
  z-index: 3;
}

.header_icon_admin {
  display: inline;
  transform: scale(1.1);
}

@media only screen and (min-width: 1024px) {
  .header__container {
    padding: 0 40px;
    gap: 20px;
  }
  .header__title {
    font-size: 24px;
  }
  .header__icon {
    min-width: 24px;
    min-height: 24px;
  }
}
</style>
