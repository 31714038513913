import { createWebHistory, createRouter } from "vue-router";

import store from "@/store/index";
import User from "@/services/user-services";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/kitchenDisplaySystem",
    name: "kitchenDisplaySystem",
    component: () => import("@/views/KitchenDisplaySystem.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/monthlyCostInput",
    name: "monthlyCostInput",
    component: () => import("@/views/MonthlyCostInput.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/Orders.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ordersSuperAdmin/:idLocal",
    name: "ordersSuperAdmin",
    component: () => import("@/views/Orders.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/historicSalesSuperAdmin/:idLocal",
    name: "historicSalesSuperAdmin",
    component: () => import("@/views/HistoricSales.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/historic_sales",
    name: "historic_sales",
    component: () => import("@/views/HistoricSales.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/menuList",
    name: "menuList",
    component: () => import("@/views/MenuList.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/menuListSuperAdmin/:idLocal",
    name: "menuListSuperAdmin",
    component: () => import("@/views/MenuList.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/recipe",
    name: "menuRecipe",
    component: () => import("@/views/MenuRecipe.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/inventory",
    name: "inventory",
    component: () => import("@/views/Inventory.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/programacionanualrecetas",
    name: "programacionanualrecetas",
    component: () => import("@/views/ProgramacionAnualRecetas.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/inhouseOrders",
    name: "inhouseOrders",
    component: () => import("@/views/InhouseOrders.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/inhouseOrdersSuperAdmin/:idLocal",
    name: "inhouseOrdersSuperAdmin",
    component: () => import("@/views/InhouseOrders.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/salesDashboard",
    name: "salesDashboard",
    component: () => import("@/views/SalesDashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/soldProductsDashboard",
    name: "soldProductsDashboard",
    component: () => import("@/views/SoldProductsDashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/sunatReport",
    name: "sunatReport",
    component: () => import("@/views/statistics_reports/SunatReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/salesRawData",
    name: "salesRawData",
    component: () => import("@/views/statistics_reports/SalesRawData.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orderDetailsRaw",
    name: "orderDetailsRaw",
    component: () =>
        import("@/views/statistics_reports/OrderDetailHistoricList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporteRequerimientoConsolidado",
    name: "reporteRequerimientoConsolidado",
    component: () =>
        import("@/views/statistics_reports/ReporteRequerimientoConsolidado"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reporteRequerimientoDetallado",
    name: "reporteRequerimientoDetallado",
    component: () =>
        import("@/views/statistics_reports/ReporteRequerimientoDetallado.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/userDatabase",
    name: "userDatabase",
    component: () => import("@/views/statistics_reports/UserDatabase.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/salesPerUser",
    name: "salesPerUser",
    component: () => import("@/views/statistics_reports/SalesPerUserNew.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/webConfig",
    name: "webConfig",
    component: () => import("@/views/WebConfiguration.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/staffAccess",
    name: "staffAccess",
    component: () => import("@/views/BusinessStaffAccess.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.onError((error) => {
  const errorMessage = error.message.toLowerCase();
  const hasChunkError =
      errorMessage.includes("chunk failed") ||
      errorMessage.includes("loading chunk") ||
      errorMessage.includes("loading css chunk");

  if (hasChunkError) {
    const message =
        "Se ha lanzado una actualización de la web. Por favor, recarga la página para continuar.";
    setTimeout(() => {
      window.alert(message);
      window.location.reload();
    }, 1900);
  }
});

let firstOcurrency = true;
async function loadLocalStorageData() {
  let orderProducts = await JSON.parse(localStorage.getItem("orderProducts"));
  if (orderProducts) {
    orderProducts.forEach((product) => {
      store.commit("pushOrderProduct", product);
    });
  }
  let user = await JSON.parse(localStorage.getItem("user"));
  if (user) {
    await User.setAuthHeaders(user.token);
    store.commit("setUser", user);
    store.commit("setAuthenticatedTrue");
  } else {
    store.commit("setAuthenticatedFalse");
    //router.push("/")
  }
  let customerOrder = await JSON.parse(localStorage.getItem("customerOrder"));
  if (customerOrder) {
    store.commit("setCustomerOrder", customerOrder);
  }
  let orderDetails = await JSON.parse(localStorage.getItem("orderDetails"));
  if (orderDetails) {
    store.commit("setOrderDetails", orderDetails);
  }
  let categorySelected = await JSON.parse(
      localStorage.getItem("categorySelected")
  );
  if (categorySelected) {
    store.commit("setCategorySelected", categorySelected);
  }
  let productSelected = await JSON.parse(
      localStorage.getItem("productSelected")
  );
  if (productSelected) {
    store.commit("setProductSelected", productSelected);
  }
  let insumoSelected = await JSON.parse(localStorage.getItem("insumoSelected"));
  if (insumoSelected) {
    store.commit("setInsumoSelected", insumoSelected);
  }
  let businessProfileSelected = await JSON.parse(
      localStorage.getItem("businessProfileSelected")
  );
  if (businessProfileSelected) {
    store.commit("setBusinessProfileSelected", businessProfileSelected);
  }
  let adminLocalInfo = await JSON.parse(localStorage.getItem("adminLocalInfo"));
  if (adminLocalInfo) {
    store.commit("setAdminLocalInfo", adminLocalInfo);
  }
  let deliveryNotificationStatus = await JSON.parse(
      localStorage.getItem("deliveryNotificationStatus")
  );
  if (deliveryNotificationStatus) {
    store.commit("setDeliveryNotificationStatus", deliveryNotificationStatus);
  }
  let webVersion = await JSON.parse(localStorage.getItem("webVersion"));
  if (webVersion) {
    store.commit("setWebVersion", webVersion);
  }
  let documentTypeForPrint = await JSON.parse(
      localStorage.getItem("documentTypeForPrint")
  );
  if (documentTypeForPrint) {
    store.commit("setDocumentTypeForPrint", documentTypeForPrint);
  }
}

router.beforeEach(async (to, from, next) => {
  if (firstOcurrency) {
    await loadLocalStorageData();
    firstOcurrency = false;
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (store.state.isAuthenticated) {
        next();
        return
      }
      await router.push("/");
    }
    next();
    return
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.isAuthenticated) {
      next();
      return
    }
    await router.push("/");
  }
  next();
});
export default router;
