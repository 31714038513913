<template>
  <article class="modal__bg">
    <section class="modal__container-small">
      <p>Para continuar, debe aceptar recibir notificaciones a su dispositivo</p>
      <div class="modal__buttons">
        <ButtonTexto
            type="button"
            @click="logoutAndClearData"
        >
          Salir
        </ButtonTexto>
        <ButtonPrimary
            type="button"
            @click="requestPermissionWaiter"
        >
          Aceptar
        </ButtonPrimary>
      </div>
    </section>
  </article>
</template>

<script setup>
import {defineEmits} from "vue";
import ButtonTexto from "@/components/new/buttons/ButtonTexto";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";

const emits = defineEmits(['close_modal_confirm_notification', 'requestPermissionWaiter', 'logoutAndClearData'])

function logoutAndClearData(){
  emits('logoutAndClearData');
}

function requestPermissionWaiter(){
  emits('requestPermissionWaiter');
}
</script>

<style scoped>
  .modal__container-small{width: clamp(100px, 100%, 360px); }

  p{ text-align: center; }
</style>