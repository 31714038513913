<template>
  <div class="modal__bg" @click="emitClose" >
    <div class="modal_position" @click.stop>
      <svg
          class="icon-close"
          @click="$emit('close')"
          width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z" fill="currentColor"/>
      </svg>
      <p>Por favor, habilita las notificaciones en tu navegador web</p>
      <ButtonTexto @click="$emit('ok')">Regresar</ButtonTexto>
    </div>
  </div>
</template>

<script>
import ButtonTexto from "@/components/new/buttons/ButtonTexto.vue";

export default {
  components: {ButtonTexto},
  emits: ['ok', 'close'],
  async mounted () {
  },
  data () {
    return {

    }
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
  },
  computed: {

  },
  watch: {

  },

}

</script>

<style scoped>
  .modal_position{
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: white;
    border-radius: 16px;
    align-items: center;
    padding: 15px 20px;
    z-index: 10;
  }

  .icon-close{
    cursor: pointer;
    margin-left: auto;
    scale: 1;
    transition: scale .3s linear;
  }

  .icon-close:hover{
    color: var(--primary);
    cursor: pointer;
    scale: 1.5;
  }

  .modal_position p{
    color: var(--primary_black);
    font-weight: var(--medium);
    font-size: 20px;
    text-align: center;
  }

  .modal_position button{ font-size: 18px; }

  @media only screen and (min-width: 425px){
    .modal_position{ width: 90%; }
  }

  @media only screen and (min-width: 600px){
    .modal_position{
      width: 500px;
      padding: 30px 40px;
    }
    .modal_position p{ font-size: 24px; }
  }
</style>