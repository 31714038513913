import axios from 'axios'

export default {

    async logoutUser() {
        return await axios({
            method: 'DELETE',
            url: `${process.env.VUE_APP_API}/user/logout/`
        })
    },

    async changeAdminPassword(data) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/user/admin/password/`,
            data
        })
    },

    async getValidUsers () {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/historic/users/total/`,
        })
    },

    async getUserData (data = {
        'user_list': [],
    }) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/historic/users/total/`,
            data
        })
    },

    async getCustomerInfo (data) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/user/notes/`,
            data
        })
    },
    async updateUserNotes (data) {
        return await axios({
            method: 'PUT',
            url: `${process.env.VUE_APP_API}/user/notes/`,
            data
        })
    },

    async infoUser () {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/user/staff/`
        })
    },
    async login (data) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/account/signin/admin/`,
            data
        })
    },

    async updateRegId (data) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/user/reg_id/`,
            data
        })
    },

    async setAuthHeaders (accessToken) {
        axios.defaults.headers.common['platform'] = 'Web'
        axios.defaults.headers.common['device'] = 'Web'
        if (accessToken != null) {
            axios.defaults.headers.common.Authorization = "token " + accessToken
        } else {
            axios.defaults.headers.common.Authorization = ''
        }
    },

}