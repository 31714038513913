
import { createStore } from 'vuex'
import db from '../firebase'

const store = createStore({
    state: {
        showModalLoginRegister: false,
        print_clicked: false,
        invoice_clicked: false,
        user: {
        },
        isAuthenticated: false,
        categorySelected: '',
        orderProducts: [],
        productSelected: {},
        businessProfileSelected: {},
        adminLocalInfo: {},
        additionalSelected: {},
        insumoSelected: {},
        sizeSelected: {},
        customerOrder: '',
        orderDetails: '',
        database: db,
        deliveryNotificationStatus: true,
        productCustomization: [],
        webVersion: 0,
        documentTypeForPrint: 0,
        meassureUnits: [
            {
                name: 'Gramos',
                value: 'gr'
            },
            {
                name: 'Kilogramos',
                value: 'kg'
            },
            {
                name: 'Mililitros',
                value: 'ml'
            },
            {
                name: 'Litros',
                value: 'l'
            },
            {
                name: 'Galones',
                value: 'gal'
            },
            {
                name: 'Onzas',
                value: 'oz'
            },
            {
                name: 'Unidad',
                value: 'un'
            },

        ]
    },
    mutations: {
        setOrderProductMenos (state, index) {//resta en 1 el SelectCantidad de id del product
            //state.orderProducts = state.orderProducts.map(product => { if (product.id == data.id) { product.SelectCantidad = product.SelectCantidad - 1 } return product })

            state.orderProducts[index].SelectCantidad = state.orderProducts[index].SelectCantidad - 1
            localStorage.setItem('orderProducts', JSON.stringify(state.orderProducts))

        },
        setOrderProductMas (state, index) {//aumenta en 1 el SelectCantidad de id del product
            //state.orderProducts = state.orderProducts.map(product => { if (product.id == data.id) { product.SelectCantidad = product.SelectCantidad + 1 } return product })
            state.orderProducts[index].SelectCantidad = state.orderProducts[index].SelectCantidad + 1

            localStorage.setItem('orderProducts', JSON.stringify(state.orderProducts))

        },
        setUser (state, data) {
            state.user = data
            localStorage.setItem('user', JSON.stringify(data))
        },
        setBusinessProfileSelected (state, data) {
            state.businessProfileSelected = data
            localStorage.setItem('businessProfileSelected', JSON.stringify(data))
        },
        setAdminLocalInfo (state, data) {
            state.adminLocalInfo = data
            localStorage.setItem('adminLocalInfo', JSON.stringify(data))
        },
        setCategorySelected (state, data) {
            state.categorySelected = data
            localStorage.setItem('categorySelected', JSON.stringify(data))
        },
        setProductSelected (state, data) {
            state.productSelected = data
            localStorage.setItem('productSelected', JSON.stringify(data))
        },
        setSizeSelected (state, data) {
            state.sizeSelected = data
            localStorage.setItem('sizeSelected', JSON.stringify(data))
        },
        setAdditionalSelected (state, data) {
            state.additionalSelected = data
            localStorage.setItem('additionalSelected', JSON.stringify(data))
        },
        setInsumoSelected (state, data) {
            state.insumoSelected = data
            localStorage.setItem('insumoSelected', JSON.stringify(data))
        },
        pushOrderProduct (state, data) {
            state.orderProducts.push(data)
            localStorage.setItem('orderProducts', JSON.stringify(state.orderProducts))
        },
        updateOrderProduct (state, data) {
            let index = data.index
            delete data.index
            state.orderProducts[index] = data
            localStorage.setItem('orderProducts', JSON.stringify(state.orderProducts))
        },
        setAuthenticatedTrue (state) {
            state.isAuthenticated = true
        },
        setAuthenticatedFalse (state) {
            state.isAuthenticated = false
        },
        deleteItemOfOrderProducts (state, index) {
            state.orderProducts.splice(index, 1)
            localStorage.setItem('orderProducts', JSON.stringify(state.orderProducts))
        },
        clearProducts (state) {
            state.orderProducts = [];
            localStorage.removeItem('orderProducts')
        },
        setShowModalLoginRegister (state, value) {
            state.showModalLoginRegister = value
        },
        setPrintClicked (state, value) {
            state.print_clicked = value
        },
        setInvoiceClicked (state, value) {
            state.invoice_clicked = value
        },
        setCustomerOrder (state, data) {
            state.customerOrder = data
            localStorage.setItem('customerOrder', JSON.stringify(data))
        },
        setOrderDetails (state, data) {
            state.orderDetails = data
            localStorage.setItem('orderDetails', JSON.stringify(data))
        },
        setDeliveryNotificationStatus (state, value) {
            state.deliveryNotificationStatus = value
        },
        setProductCustomization (state, data) {
            state.productCustomization = data
            localStorage.setItem('productCustomization', JSON.stringify(data))
        },
        setWebVersion (state, data) {
            state.webVersion = data
            localStorage.setItem('webVersion', JSON.stringify(data))
        },
        setDocumentTypeForPrint (state, data) {
            state.documentTypeForPrint = data
            localStorage.setItem('documentTypeForPrint', JSON.stringify(data))
        }

    },
})

export default store
