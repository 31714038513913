<template>
  <div
      id="app__wrapper"
  >
    <RebrandHeader
        :style="(documentTypeForPrint > 0)?'display:none':''"
    >
    </RebrandHeader>
    <transition name="slide-fade">
      <ModalLogin
          v-if="!isAuthenticated && showModalLoginRegister"
          :active="showModalLoginRegister"
      ></ModalLogin>
    </transition>
    <transition name="slide-fade">
      <ModalConfirmNotification
          v-if="showModalConfirmNotification"
          @logoutAndClearData="logoutAndClearData"
          @requestPermissionWaiter="requestPermissionWaiter"
      />
    </transition>
    <main class="main" v-show="documentTypeForPrint === 0">
      <router-view>
      </router-view>
    </main>
    <ModalPrintOrderInfo
        v-if="documentTypeForPrint === 1"
        @finish="finished_printing"
    >

    </ModalPrintOrderInfo>
    <ModalPrintInvoice
        v-if="documentTypeForPrint === 2"
        @finish="finished_printing"
    >
    </ModalPrintInvoice>
    <transition name="slide-fade">
      <ModalPedirUbicacion
          v-if="showModalUbicacion"
          @ok="showModalUbicacion=false"
          @close="showModalUbicacion=false"
      >
      </ModalPedirUbicacion>
    </transition>
  </div>
</template>

<script>

import {mapMutations, mapState} from "vuex"
import UserServices from "@/services/user-services";
import router from './router'
import {db, messaging, firebaseConfig} from "@/firebase"
import { onSnapshot, collection } from "firebase/firestore";
import ModalPrintOrderInfo from "@/components/MODALS/ModalPrintOrderInfo";
import ModalPrintInvoice from "@/components/MODALS/ModalPrintInvoice";
import ModalPedirUbicacion from "@/components/MODALS/ModalPedirUbicacion";
import RebrandHeader from "@/components/MainHeader";
import ModalLogin from "@/components/MODALS/ModalLogin";
import {getToken, onMessage} from "firebase/messaging";
import ModalConfirmNotification from "@/components/MODALS/ModalConfirmNotification";

export default {
  components: {
    ModalConfirmNotification,
    ModalLogin,
    RebrandHeader,
    ModalPedirUbicacion,
    ModalPrintInvoice,
    ModalPrintOrderInfo,

  },
  computed: {
    ...mapState(['showModalLoginRegister', 'isAuthenticated', 'deliveryNotificationStatus', 'user',
      'webVersion', 'documentTypeForPrint']),
  },
  data () { // documentTypeForPrint: 1 comanda, 2 boleta/factura
    return {
      initialListen: true,
      showModalUbicacion: false,
      subscription_firebase: null,
      mensaje_moroso: 'Servicio suspendido hasta que regularicen su pago.',
      myTrack: null,
      current_state_notif: null,
      has_loaded_worker: false,
      showModalConfirmNotification: false,
    }
  },

  watch: {
    isAuthenticated: async function () {
      this.initialListen = true
      if (this.subscription_firebase !== null) {
        console.log("desactivara firestore")
        this.subscription_firebase()
        this.subscription_firebase = null
      }
      if (this.isAuthenticated) {
        if (this.user.is_waiter || this.user.is_admin) {
          this.showModalConfirmNotification = true
        }
        if (this.user.is_staff || this.user.is_super_admin || this.user.is_admin) {
          console.log("llamara firestore")
          await this.firestoreListennerApp()
          return
        }
        if (this.user.business_staff && this.user.access_list.includes('1')) {
          console.log("firestore business_staff")
          await this.firestoreListennerApp()
        }
      } else {
        this.showModalConfirmNotification = false
      }
    },
  },
  async beforeCreate() {
  },
  async created() {
    // localStorage.clear()
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", getComputedStyle(document.body).getPropertyValue('--primary'));//setea el theme color desde una variable css
    console.log("connection is",   process.env.NODE_ENV)

  },
  async mounted() {

    setTimeout(async () => {

      if (this.isAuthenticated) {
        await UserServices.setAuthHeaders(this.user.token)
        await this.getUserPermissions()
      }
      if ((this.webVersion < parseInt(process.env.VUE_APP_CURRENT_VERSION)) && this.webVersion !== 0) {
        this.$toast.info('Se refrescará su navegador web para que tenga la versión más reciente.')
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }
      this.setWebVersion(parseInt(process.env.VUE_APP_CURRENT_VERSION))
    }, 100)
  },
  methods: {
    ...mapMutations(['setUser', 'setAuthenticatedFalse', 'setWebVersion', 'setDocumentTypeForPrint']),


    registerFirebaseMessagingSW() {
      let self = this
      if (this.has_loaded_worker) {
        this.$toast.error("Ya se registró el service worker")
        return
      }
      const firebase_full_config = firebaseConfig
      firebase_full_config.user_token = this.user.token
      firebase_full_config.base_api = `${process.env.VUE_APP_API}/user/reg_id/`
      if ('serviceWorker' in navigator) {

        navigator.serviceWorker.register('/firebase-messaging-sw_new.js?config=' + encodeURIComponent(JSON.stringify(firebase_full_config)))
            .then((registration) => {
              console.log('Service Worker registrado con éxito:', registration);
              self.has_loaded_worker = true
              // Usa el Service Worker registration para manejar Firebase Messaging
              this.requestPermissionAndGetToken(registration);
            })
            .catch((error) => {
              console.error('Error al registrar el Service Worker:', error);
            });
      }
    },

    async requestPermissionAndGetToken(registration) {

      try {
        const token = await getToken(messaging, {vapidKey: 'BLACmCBgpQiL_avi6VJO2vaKLY5lXR6DhOZQndr9TmMntmvOVF6TGrAzdhuAYSoJtXTEmLp6CDsz6zEjcVJEYw4', serviceWorkerRegistration: registration});
        if (token) {
          let response = await UserServices.updateRegId({
            reg_id: token,
            from_service_worker: false
          })
          if (response.data.success) {
            console.log("token front registrado con exito")
          } else {
            console.log("token front error")
          }
          onMessage(messaging, (payload) => {
            const notification = payload.notification
            const options = {
              body: notification.body,
            };
            registration.showNotification(notification.title, options);
            this.$toast.success(notification.body)
          })
        }
      } catch (error) {
        console.error('Error al solicitar permisos de notificación o al obtener el token de FCM:', error);
      }
    },

    requestPermissionWaiter() {
      if (!this.isAuthenticated) {
        return
      }
      if (this.current_state_notif !== null && this.current_state_notif !== 'granted') {
        this.$toast.error("Debe actualizar la configuración en su navegador web.")
        return;
      }
      console.log('Hiciste click')
      Notification.requestPermission().then((permission) => {
        this.current_state_notif = permission
        if (permission === "granted") {
          this.showModalConfirmNotification = false
          console.log("granted frontend", permission)
          this.registerFirebaseMessagingSW()
          this.current_state_notif = permission
        }
      })
    },

    async actualizarTokenServicioTrabajador(token) {

      let response = await UserServices.updateRegId({
        reg_id: token,
        from_service_worker: false
      })
      if (response.data.success) {
        console.log("token front", "exito")
        // onMessage(messaging, (payload) => {
        //   console.log("front notifffff", payload)
        //   this.$toast.success(payload.notification.body)
        // });
      } else {
        console.log("token front", "fracaso")
      }

    },
    finished_printing() {
      this.setDocumentTypeForPrint(0)
    },
    async getUserPermissions() {

      try {
        let response = await UserServices.infoUser()
        if (response.data.success) {
          if (!response.data.data.is_admin && !response.data.data.is_super_admin && !response.data.data.is_staff && !response.data.data.is_chef
              && !response.data.data.is_waiter && !response.data.data.business_staff) {

            this.setAuthenticatedFalse()
            await localStorage.clear()
            this.$emit('close')
            await this.$router.push("/")
            return
          }
          this.setUser(response.data.data)
        } else {
          console.log("error es", response)
          this.$toast.error(response.data.message)
        }
      } catch (e) {
        console.log("error es", e)
        if (e.response.status === 500 || e.response.status === 401) {
            await this.logoutAndClearData()
            this.$toast.error('Hubo un error. Tiene que volver a iniciar sesión para continuar.')
        } else {
          this.$toast.error(e.toString())
        }
      }
    },
    async logoutAndClearData() {
      this.setAuthenticatedFalse()
      await localStorage.clear()
      await UserServices.setAuthHeaders(null)
      this.$emit('close')
      await this.$router.push("/")
      this.showModalConfirmNotification = false
    },
    async firestoreListennerApp() {
      this.myTrack = new Audio(require('@/audio/audio.mp3'))

      this.subscription_firebase =  onSnapshot(collection(db, 'cenfotur__invoice'),
          {includeMetadataChanges: true},
          (snapshot) => {

            if (this.initialListen) {
              if (process.env.NODE_ENV === 'development') {
                console.log("hizo initial listen: ", snapshot);
              }
              this.initialListen = false
            } else {
            snapshot.docChanges().forEach((snapshot) => {
              if (snapshot.type === "added") {

                if (process.env.NODE_ENV === 'development') {
                  console.log('se añadió info a app.vue', snapshot.doc.data())
                }

                if (snapshot.doc.data().status !== '0') {
                  return
                }

                  switch (snapshot.doc.data().type_delivery) {
                    case '1':
                    case 1:
                      return
                    case '2':
                    case 2:
                      return
                    case '3':
                    case 3:
                      if (!this.deliveryNotificationStatus) {
                        return;
                      }
                      break
                    case '4':
                      case 4:
                        return
                  }

                let self = this
                document.addEventListener('click', this.handleClickOutside);

                this.myTrack.loop = true
                    this.myTrack.play()

                    let response = snapshot.doc.data().message

                    const notification = {
                      title: 'Quick eat!',
                      options: {
                        body: response,
                        requireInteraction: true,
                        icon: 'https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png',
                        image: 'https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png',
                        badge: 'https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png'
                      },
                      events: {
                        onerror: function () {
                          //console.log('Error en la notif');
                        },
                        onclick: async function () {
                          self.myTrack.pause()
                          self.myTrack.currentTime = 0
                          document.removeEventListener('click', self.handleClickOutside);
                          await router.push('/orders');
                        },
                        onclose: function () {
                          self.myTrack.pause()
                          self.myTrack.currentTime = 0
                          document.removeEventListener('click', self.handleClickOutside);
                        },
                        onshow: function () {
                        }
                      }
                    }
                    this.$notification.show(notification.title, notification.options, notification.events)
                    this.$toast.success(response)

              }
            })
          }})
    },

    handleClickOutside() {
      // Detener la reproducción del sonido aquí
      if (this.myTrack.currentTime === 0) {
        return
      }
      this.myTrack.pause();
      this.myTrack.currentTime = 0;

      // También puedes eliminar el event listener para evitar múltiples llamadas innecesarias
      document.removeEventListener('click', this.handleClickOutside);
    },
  },
}
</script>
<style scoped>
#app__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main{
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.main > *{ flex-grow: 1; }
</style>
