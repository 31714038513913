<template>
  <div class="modal__bg" @click="closeModalLogin()">
    <form
        class="component_login opening-animation"
        @click.stop
        @submit.prevent="loginButton"
        ref="form"
    >
      <span class="icon-login"
            @click="closeModalLogin"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z" fill="currentColor"/></svg>
      </span>
      <div class="component_login-information">
        <h2 class="dialog__title">¡Te damos la bienvenida!</h2>
        <p>Inice sesión para continuar</p>
      </div>
      <InputComponent
          :placeholder="'Correo'"
          :type="'email'"
          :required="true"
          :bg_color="'#F7F7F7'"
          v-model="login.email"
      >
      </InputComponent>
      <PasswordComponent
          :placeholder="'Contraseña'"
          :required="true"
          :bg_color="'#F7F7F7'"
          :disabled="loading"
          v-model="login.password"
      >
      </PasswordComponent>
      <ButtonPrimary
          type="submit"
          :disabled="loading"
          :loading="loading"
      >
        {{login_words}}
      </ButtonPrimary>
    </form>
  </div>
</template>

<script>
import User from "@/services/user-services"
import {mapMutations, mapState} from "vuex"
import InputComponent from "@/components/new/inputs/InputComponent";
import PasswordComponent from "@/components/new/inputs/PasswordComponent";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
export default {
  name: 'ModalLogin',
  props: ['active'],
  emits: ['close', ],
  components: {
    ButtonPrimary,
    PasswordComponent,
    InputComponent,
  },
  data () {
    return {
      loading: false,
      login: {
        email: '',
        password: "",
        platform: 'Web',
      },
      deliveryStatus: true,
      reset_key: 0,
      visible_password: false,
      socios_page: false,
    }
  },
  computed: {
    ...mapState(['user']),

    login_words() {
      if (this.loading) {
        return 'Cargando...'
      } else {
        return 'Ingresar'
      }
    }
  },
  watch: {
    active() {
      this.reset_key += 1
      this.loading = false
      this.login.email = ''
      this.login.password = ''
    },
  },
  beforeCreate() {
    if (this.$refs.form) {
      this.$refs.form.classList.replace('closing-animation', 'opening-animation');
    }
  },
  async mounted() {

  },
  methods: {
    ...mapMutations(["setUser", 'setAuthenticatedTrue',
      'setDeliveryNotificationStatus', 'setShowModalLoginRegister']),

    closeModalLogin() {
      this.$refs.form.classList.replace('opening-animation','closing-animation');
      this.setShowModalLoginRegister(false);
    },
    updatePassword(event) {
      this.login.password = event
    },
    async loginButton () {
      try {
        this.loading = true
        let response = await User.login(this.login)

        if (response.data.success) {
          await User.setAuthHeaders(response.data.data.token)
          if (response.data.data.is_staff) {
            this.deliveryStatus = false
          }
          this.setDeliveryNotificationStatus(this.deliveryStatus)
          this.$toast.success(`Bienvenido ${response.data.data.full_name}`)
          this.setUser(response.data.data)
          this.setAuthenticatedTrue()
          this.loading = true
          this.$emit('close')
          if (this.user.is_chef) {
            this.$router.push("/kitchenDisplaySystem")
            return
          }
          if (this.user.is_admin) {
            this.$router.push("/orders")
            return
          }
          if (this.user.is_waiter) {
            this.$router.push("/inhouseOrders")
            return
          }
          if (this.user.is_super_admin) {
            this.$router.push('/orders')
          }
          return
        }
        this.loading = false
        this.$toast.error(response.data.message)

      } catch (error) {
        this.$toast.error(error)
        this.loading = false
      }
    },
  }
}
</script>

<style>
  h2{
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: var(--bold);
  }

  p{ font-weight: var(--regular); }

  .icon-login{
    cursor: pointer;
    margin-left: auto;
  }

  .component_login-information{ text-align: center; }

  .component_login {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 525px;
    background-color: var(--text-white);
    box-shadow: var(--box-shadow);
    border-radius: 16px;
    padding: 20px 10px;
  }

  @media only screen and (min-width: 425px){
    .component_login{ padding: 20px; }
  }

  @media only screen and (min-width: 768px){
    .component_login{ padding: 40px 80px; }
  }

  .closing-animation{ animation: hidden-modal 0.5s linear; }

  .opening-animation{
    -webkit-animation: show-modal 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: show-modal 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @keyframes show-modal {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes hidden-modal {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
</style>