import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { getFirestore } from "firebase/firestore";


// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyCb9dzVlmvZ5kPhOreRhaPHB8oa0BIm0sw",
    authDomain: "cenfotur-costos-almacenamiento.firebaseapp.com",
    databaseURL: "https://cenfotur-costos-almacenamiento-default-rtdb.firebaseio.com",
    projectId: "cenfotur-costos-almacenamiento",
    storageBucket: "cenfotur-costos-almacenamiento.appspot.com",
    messagingSenderId: "493097744117",
    appId: "1:493097744117:web:6d7ae6b32e0e86f583b7bc"
}
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
// const user_token = '7815951215c01a32c2e7accb003936320def95c8'

// const firestore = getFirestore()
// firestore.settings({ timestampsInSnapshots: true })

// utils
const db = getFirestore(app)
// const trackingDatabase = getDatabase(firebaseApp)

// export utils/refs
export {
    db, firebaseConfig, messaging
    // trackingDatabase
}