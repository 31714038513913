<template>
  <div class="fondo_impresion_invoice">
    <div class="invoice_title">{{ business_tax_name }}</div>
    <div class="invoice_title">{{legal_address}}</div>
    <div class="invoice_title">Ruc: {{ business_tax_id }}</div>
    <div style="width: 100%; border-bottom: dashed"></div>
    <div> Cliente: {{customerInvoice()}}</div>
    <div v-if="customerOrder.ruc !== '' && customerOrder.ruc != null"> Documento: {{customerOrder.ruc}}</div>
    <div style="width: 100%; border-bottom: dashed"></div>
    <div class="invoice_title">{{title_invoice()}}</div>
    <div style="width: 100%; border-bottom: dashed"></div>
    <div> Fecha: {{hora_pedido(customerOrder.order_date)}} </div>
    <table class="table_products_print_invoice">
      <tr class="table_header_print_invoice" >
        <th class="column_cantidad_print_invoice" style="width: 10%">Cant.</th>
        <th style="width: 70%">Producto</th>
        <th class="column_subtotal_print_invoice" style="width: 20%"> S/.</th>
      </tr>
      <tr
          v-for="(item,key) in order_detail_temp_obj"
          :key="key"
          style="border-bottom: dashed; border-bottom-color: black;"
      >
        <td class="item_cantidad_cont_print_invoice">
          {{item.cant}}
        </td>
        <td class="info_print_invoice">
          <p style="margin-bottom: 0">{{ item.product_name }}</p>
          <ul style="margin-bottom: 0">
            <li v-for="size in item.product_size_names" :key="size">a: {{size}}</li>
            <li v-if="item.product_sizes2_names !== null && item.product_sizes2_names.length > 0">
              <ul>
                <li v-for="size in item.product_sizes2_names" :key="size">b: {{size}}</li>
              </ul>
            </li>
            <li v-if="item.product_sizes3_names !== null &&item.product_sizes3_names.length > 0">
              <ul>
                <li v-for="size in item.product_sizes3_names" :key="size">c: {{size}}</li>
              </ul>
            </li>
            <li v-if="item.product_sizes4_names !== null &&item.product_sizes4_names.length > 0">
              <ul>
                <li v-for="size in item.product_sizes4_names" :key="size">d: {{size}}</li>
              </ul>
            </li>
          </ul>
          <p style="margin-bottom: 0" v-if="item.comments">Obs: {{ item.comments }}</p>

        </td>
        <td style="text-align: end; width: 20%">{{(parseFloat(item.subtotal)).toFixed(2)}}</td>
      </tr>
      <tr v-if="customerOrder.type_delivery === '3' && parseFloat(customerOrder.delivery) > 0" style="border-bottom: dashed; border-bottom-color: black;">
        <td class="item_cantidad_cont_print_invoice">
          1
        </td>
        <td class="info_print_invoice">
          Costo de envío
        </td>
        <td style="text-align: end; width: 20%">{{(parseFloat(customerOrder.delivery)).toFixed(2)}}</td>
      </tr>

    </table>
    <div v-if="customerOrder.total !== customerOrder.total_coupon" class="costos_print_invoice">
      <span>DESCUENTO: "{{discount_title(customerOrder.coupon_title)}}"</span>
      <span>S/{{ descuento()}}</span>
    </div>
    <div class="costos_print_invoice">
      <span>OP. GRAVADAS</span>
      <span>S/{{gravado_con_delivery().toFixed(2)}}</span>
    </div>
    <div class="costos_print_invoice">
      <span>IGV</span>
      <span>S/{{(total_con_delivery() - gravado_con_delivery()).toFixed(2)}}</span>
    </div>
    <div  class="costos_print_invoice" style="border-bottom: dashed">
        <span>
          IMPORTE TOTAL
        </span>
      <span>
          S/{{total_con_delivery().toFixed(2)}}
        </span>
    </div>
    <div>
      {{metodoPago(customerOrder)}}
    </div>
    <div v-if="customerOrder.name_user_order">Usuario:&nbsp;{{customerOrder.name_user_order}}</div>
    <div style="display: flex; align-items: flex-start" v-if="customerOrder.phone_user_order != null && customerOrder.phone_user_order !== ''">
      Celular:&nbsp;{{customerOrder.phone_user_order}}
    </div>
    <div v-if="customerOrder.type_delivery === '3'">
      Dirección de envío:&nbsp;{{customerOrder.address}}
    </div>
    <div v-if="customerOrder.comments !== ''" >
      Comentarios del cliente:&nbsp;{{customerOrder.comments}}
    </div>
    <div v-if="customerOrder.type_delivery === '1'">
      Número de personas:&nbsp;{{customerOrder.cant_people}}
    </div>
    <div v-if="customerOrder.change_time">
      Programado:&nbsp;{{`${programacion(customerOrder.time_to_order)}`}}
    </div>
    <div v-if="customerOrder.ruc != null && customerOrder.ruc.toString() !== ''">
      {{getInvoiceAttributes(customerOrder.invoice_type, customerOrder.ruc)}}
    </div>
    <div v-if="customerOrder.razon_social != null && customerOrder.razon_social.toString() !== ''">
      {{getInvoiceRazonSocial(customerOrder.invoice_type, customerOrder.razon_social)}}
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import moment from "moment";
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: "ModalPrintInvoice",
  emits: ['finish'],
  data () {
    return {
      medio_de_pago_cliente: '',
      order_detail_temp_obj: [],
      business_tax_name: '',
      legal_address: '',
      business_tax_id: ''
    }
  },
  mounted() {

    this.business_tax_id = process.env.VUE_APP_TAX_ID
    this.business_tax_name = process.env.VUE_APP_TAX_NAME
    this.legal_address = process.env.VUE_APP_LEGAL_ADDRESS

    this.order_detail_temp_obj = cloneDeep(this.orderDetails);
    for (let i=0; i<this.order_detail_temp_obj.length; i++) { // desde aqui
      if (this.order_detail_temp_obj[i].order !== this.customerOrder.id) {
        this.$toast.error('Hubo un error con la información del pedido')
        this.emitClose()
        return
      }
      if (this.order_detail_temp_obj[i].product_size_names !== null && this.order_detail_temp_obj[i].product_size_names !== '') {
        if (this.order_detail_temp_obj[i].product_size_names.includes('/')) {
          // El formato es BBQ (2)/ Ketchup (1)/ Mayonesa (2)
          this.order_detail_temp_obj[i].product_size_names = this.order_detail_temp_obj[i].product_size_names.split('/')
        } else {
          // El formato es BBQ,Ketchup,Mayonesa
          this.order_detail_temp_obj[i].product_size_names = this.order_detail_temp_obj[i].product_size_names.split(',')
        }
        this.order_detail_temp_obj[i].product_size_names = this.order_detail_temp_obj[i].product_size_names.map(elemento => elemento.trim());
      }
      if (this.order_detail_temp_obj[i].product_sizes2_names !== null && this.order_detail_temp_obj[i].product_sizes2_names !== '') {
        if (this.order_detail_temp_obj[i].product_sizes2_names.includes('/')) {
          // El formato es BBQ (2)/ Ketchup (1)/ Mayonesa (2)
          this.order_detail_temp_obj[i].product_sizes2_names = this.order_detail_temp_obj[i].product_sizes2_names.split('/')
        } else {
          // El formato es BBQ,Ketchup,Mayonesa
          this.order_detail_temp_obj[i].product_sizes2_names = this.order_detail_temp_obj[i].product_sizes2_names.split(',')
        }
        this.order_detail_temp_obj[i].product_sizes2_names = this.order_detail_temp_obj[i].product_sizes2_names.map(elemento => elemento.trim());
      }
      if (this.order_detail_temp_obj[i].product_sizes3_names !== null && this.order_detail_temp_obj[i].product_sizes3_names !== '') {
        if (this.order_detail_temp_obj[i].product_sizes3_names.includes('/')) {
          // El formato es BBQ (2)/ Ketchup (1)/ Mayonesa (2)
          this.order_detail_temp_obj[i].product_sizes3_names = this.order_detail_temp_obj[i].product_sizes3_names.split('/')
        } else {
          // El formato es BBQ,Ketchup,Mayonesa
          this.order_detail_temp_obj[i].product_sizes3_names = this.order_detail_temp_obj[i].product_sizes3_names.split(',')
        }
        this.order_detail_temp_obj[i].product_sizes3_names = this.order_detail_temp_obj[i].product_sizes3_names.map(elemento => elemento.trim());
      }
      if (this.order_detail_temp_obj[i].product_sizes4_names !== null && this.order_detail_temp_obj[i].product_sizes4_names !== '') {
        if (this.order_detail_temp_obj[i].product_sizes4_names.includes('/')) {
          // El formato es BBQ (2)/ Ketchup (1)/ Mayonesa (2)
          this.order_detail_temp_obj[i].product_sizes4_names = this.order_detail_temp_obj[i].product_sizes4_names.split('/')
        } else {
          // El formato es BBQ,Ketchup,Mayonesa
          this.order_detail_temp_obj[i].product_sizes4_names = this.order_detail_temp_obj[i].product_sizes4_names.split(',')
        }
        this.order_detail_temp_obj[i].product_sizes4_names = this.order_detail_temp_obj[i].product_sizes4_names.map(elemento => elemento.trim());
      }
    } // hasta qui
    setTimeout(() => {

      var original_height
      original_height = document.getElementById('app').style.height
      var original_width
      original_width = document.getElementById('app').style.width
      document.getElementById('app').style.width = '80mm';
      document.getElementById('app').style.overflow = 'hidden';

      try {
        // Print for Safari browser
        document.execCommand('print', false, null)
      } catch {
        window.print()
      }
      setTimeout(() => {
        document.getElementById('app').style.height = original_height;
        document.getElementById('app').style.width = original_width;
        document.getElementById('app').style.minHeight = '100vh';
        document.getElementById('app').style.overflow = 'unset';
      }, 150)

      this.emitClose()
    }, 350)

  },
  methods: {
    metodoPago(order) {
      switch (order.type_payment) {
        case 0:
          this.medio_de_pago_cliente = 'Pagó en Línea'
          break
        case 1:
          this.medio_de_pago_cliente = 'Paga con S/. ' + this.customerOrder.amount_pay_user
          break
        case 2:
          this.medio_de_pago_cliente = 'Paga con POS Visa / Mastercard'
          break
        case 3:
          this.medio_de_pago_cliente = 'Paga con YAPE'
          break
        case 4:
          this.medio_de_pago_cliente = 'Paga con PLIN'
          break
      }
      return this.medio_de_pago_cliente
    },
    title_invoice() {
      switch (this.customerOrder.invoice_type) {
        case 0:
          return `Boleta de venta electrónica ${this.customerOrder.invoice_serial}-${this.customerOrder.invoice_number}`
        case 1:
          return `Factura de venta electrónica ${this.customerOrder.invoice_serial}-${this.customerOrder.invoice_number}`
      }
    },
    customerInvoice() {
      if (this.customerOrder.invoice_type === 0) {
        if (this.customerOrder.ruc !== '' && this.customerOrder.ruc !== null) {
          return this.customerOrder.razon_social
        } else {
          return this.customerOrder.name_user_order
        }
      } else if (this.customerOrder.invoice_type === 1) {
        return this.customerOrder.razon_social
      }
      return this.customerOrder.name_user_order
    },
    emitClose () {
      this.$emit('finish')
    },
    descuento() {
      return (parseFloat(this.customerOrder.total_coupon) - parseFloat(this.customerOrder.total)).toFixed(2)
    },
    gravado_con_delivery() {
      return (parseFloat(this.customerOrder.total_coupon))
    },
    total_con_delivery() {
      return parseFloat(this.customerOrder.total_coupon)
    },
    total_sin_delivery() {
      return (parseFloat(this.customerOrder.total_coupon) - parseFloat(this.customerOrder.delivery))
    },
    discount_title(title) {
      if (title == null || title === '') {
        return ''
      } else {
        return title
      }
    },
    hora_pedido(date) {
      return moment(date).locale('es').format('DD/MM/YYYY hh:mm a')
    },
    programacion (fecha) {
      let aux = moment(fecha).add('5', 'hours')
      return aux.format('DD/MM hh:mm a')
    },

    getInvoiceAttributes(invoice_type, ruc) {
      let string = ''
      switch (invoice_type) {
        case 0:
          string = 'DNI: '
          break
        case 1:
          string = 'RUC: '
          break

      }
      return string + ruc
    },


    getInvoiceRazonSocial(invoice_type, razon_social) {
      let string = ''
      switch (invoice_type) {
        case 0:
          string = 'Nombre y apellido: '
          break
        case 1:
          string = 'Razón social: '
          break

      }
      return string + razon_social
    },

  },

  computed: {
    ...mapState(['customerOrder', 'orderDetails']),

    subtotal () {
      let suma = 0
      for (let i = 0; i < this.orderDetails.length; i++) {
        suma = suma + parseFloat(this.orderDetails[i].subtotal)
      }
      return suma
    },
  }
}
</script>

<style scoped>

@media print {
  .fondo_impresion_invoice {
    width: 100%;
    margin: 0;
    float: none;
  }
}
@page :left {
}
@page :right {
}

.invoice_title {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.fondo_impresion_invoice {
  font-family: Lucida Sans Typewriter Two;
  font-weight: bold !important;
  height: auto;
  font-size: 12pt !important;
  background: #fff !important;
  color: #000 !important;
  line-height: 1.5;
}
.table_products_print_invoice {
  width: 100%;
}
.table_header_print_invoice {
  border-style: dashed;
  border-color: #000;
  font-size: 11pt;

}
.column_cantidad_print_invoice {
  text-align: center;
}
.column_subtotal_print_invoice {
  text-align: center;
}
.item_cantidad_cont_print_invoice {
  text-align: center;
  border-right: dashed;
}
.costos_print_invoice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.info_print_invoice {
  display: flex;
  flex-direction: column;
  margin-left: 2px;
  line-height: 1.2;
}

</style>