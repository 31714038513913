import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/css/mystyle.scss"
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import * as uiv from 'uiv'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import '@/assets/css/ui_kit_quickeat.css'
import '@/assets/css/qe_datepicker.css'

const app = createApp(App)
app.use(uiv)
app.use(Vue3Lottie)

// Router and Store
app.use(router)
app.use(store)

app.use(ToastPlugin, {
    position: 'top-right',
    duration: 5000
});

import Vue3NativeNotification from 'vue3-native-notification'
app.use(Vue3NativeNotification, {
    requestOnNotify: true
})


// Mount app
app.mount('#app')
